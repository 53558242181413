import { Observable, ReplaySubject } from 'rxjs';

type CareOrgResourceName = string;

export class ContextService {
  private activeCareOrg = new ReplaySubject<CareOrgResourceName | null>(1);

  constructor() {}

  setActiveCareOrg(careOrg: CareOrgResourceName | null): void {
    this.activeCareOrg.next(careOrg);
  }

  getActiveCareOrg(): Observable<CareOrgResourceName | null> {
    return this.activeCareOrg;
  }
}
