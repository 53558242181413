import { BehaviorSubject, Observable } from 'rxjs';

import { GapiBindings, GapiService } from './gapi.service';

/**
 * Represents metadata about a logged in user (coach).
 */
export class User {
  displayName: string;
  constructor(googleUser: gapi.auth2.GoogleUser) {
    this.displayName = googleUser.getBasicProfile().getName();
  }
}

/**
 * Service which handles user authentication and authorization..
 */
export class AuthService {
  // All promises in this class must await this initializer to make sure that
  // the gapi GoogleAuth library has been loaded. Otherwise, we risk sending
  // back promises that are incorrect with regards to the current status
  // of our logged in user.
  private readonly initializer: Promise<void>;
  private googleAuth!: gapi.auth2.GoogleAuth;
  private readonly NO_USER: User = {
    displayName: '',
  };
  private readonly userSubject = new BehaviorSubject<User>(this.NO_USER);

  /* for some reason, moving this constructor to a single line (like prettier
     prefers) causes the following error in shared-frontend:

     ./projects/utility-module/src/services/auth.service.ts:1:0-191 - Error: Module not found: Error: Can't resolve
     '.../onduo-console-webapp/node_modules/@angular-devkit/build-angular/node_modules/@babel/runtime/helpers/esm/asyncToGenerator'
     in '/Users/fkathleen/Documents/github/dmi/onduo-console-webapp/projects/utility-module/src/services'

     so for now, just disabling eslint on that line.
  */
  constructor(
    // eslint-disable-next-line prettier/prettier
    private readonly gapiService: GapiService,
  ) {
    this.initializer = this.gapiService
      .getApis()
      .then((bindings: GapiBindings) => {
        this.googleAuth = bindings.auth;
        const currentUser = this.googleAuth.currentUser.get();
        if (currentUser.isSignedIn()) {
          this.userSubject.next(new User(currentUser));
        }
        this.googleAuth.currentUser.listen(
          (googleUser: gapi.auth2.GoogleUser) => {
            // TODO(fkathleen): look into what needs to happen with NgZone in
            // the Angular MFEs to make this work. See comment here:
            // https://github.com/verily-src/onduo-console-webapp/blob/518b610eacbb54187d7707f94839bebf8b5a567a/src/app/services/auth.service.ts#L48-L50
            if (googleUser.isSignedIn()) {
              this.userSubject.next(new User(googleUser));
            } else {
              this.userSubject.next(this.NO_USER);
            }
          }
        );
      });
  }

  async getAccessToken(): Promise<string> {
    await this.initializer;
    const currentUser = this.googleAuth.currentUser.get();
    if (!currentUser.isSignedIn()) {
      throw new Error('User not signed in');
    }
    let authResponse = currentUser.getAuthResponse(true);
    if (authResponse.expires_at < Date.now()) {
      authResponse = await currentUser.reloadAuthResponse();
    }
    return authResponse.access_token;
  }

  async isAuthenticated(): Promise<boolean> {
    await this.initializer;
    return this.googleAuth.currentUser.get().isSignedIn();
  }

  get user(): Observable<User> {
    return this.userSubject;
  }

  async signIn(): Promise<User> {
    await this.initializer;
    const googleUser = await this.googleAuth.signIn();
    return new User(googleUser);
  }

  async signOut(): Promise<void> {
    await this.initializer;
    this.googleAuth.signOut();
  }
}
