import axios, { AxiosResponse } from 'axios';

/**
 * Structure of the env.json file.
 *
 * This file and the overall environment configuration for the Care Hub
 * application (which currently doesn't include Care ConsoleV2) was basically
 * forked from ConsoleV2, but doesn't rely on the ConsoleV2 source at all.
 * Many of these fields are totally unused by Care Hub, and there is no
 * need to keep it in sync with ConsoleV2.
 *
 * When ConsoleV2 is pulled into Care Hub then we will figure out how to
 * share/not share the environment configuration.
 */
export interface EnvironmentConfig {
  env?: string;
  clientId?: string;
  scopes?: string;
  host?: string;
  port?: string;
  photoUrl?: string;
  appVersion?: string;
  // Note: this member is handled specially is it should *only* be specified
  // for integration testing and never in any deployed environment.  Therefore,
  // to avoid confusing clutter, this member is optional in env.json, and
  // absence is interpreted as a 'false' value.
  doNotRetrieveOAuthCredentialsForIntegrationTesting?: boolean;
  // Enable consent dialog
  enableConsentDialog?: boolean;
  adminGrpcHost?: string;
  adminGrpcPort?: string;
  adminGrpcPercent?: number;
}

/**
 * Service to loads JSON config from server.
 */
export class AppConfigService {
  private config: EnvironmentConfig;

  constructor() {
    this.config = {};
  }

  loadAppConfig(): Promise<void> {
    return axios.get('/env.json').then((response: AxiosResponse) => {
      this.config = response.data as EnvironmentConfig;
      console.info('Config loaded');
    });
  }

  getConfig() {
    if (Object.keys(this.config).length > 0) {
      return this.config;
    } else {
      throw new Error('Config not loaded.');
    }
  }
}
